import { render, staticRenderFns } from "./PurchaseOrderOverview.vue?vue&type=template&id=138479d2&scoped=true&"
import script from "./PurchaseOrderOverview.vue?vue&type=script&lang=js&"
export * from "./PurchaseOrderOverview.vue?vue&type=script&lang=js&"
import style0 from "./PurchaseOrderOverview.vue?vue&type=style&index=0&id=138479d2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "138479d2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAutocomplete,VBtn,VCol,VContainer,VDataTable,VIcon,VPagination,VRow,VSelect,VSpacer})
